import { useLocation } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash.orderby';
import { useFetchAllOrders } from 'api/Orders';
import { useFetchAllCarriers } from 'api/Carriers';
import Flash from 'components/Flash';
import Table from 'components/Table';
import FilterOrders from 'components/orders/FilterOrders';
import OrderDetails from 'components/orders/OrderDetails';
import OrdersTableHeader from 'components/orders/OrdersTableHeader';
import OrderLine from 'components/orders/collection/OrderLine';
import Pagination from 'components/Pagination';
import RemoveOrderLine from 'components/orders/collection/RemoveOrderLine';
import styles from './OrdersCollection.module.scss';

// missing statuses: draft, allocate_stock, allocated, refund
const ORDER_STATUS = {
  new: ['allocated'],
  processed: ['processing_by_wms'],
  shipped: ['shipped', 'returned'],
};
const PER_PAGE = 100;

// Filter by query and status
function filterOrders(orders, orderStatus, sort) {
  let matchedOrders = orderBy(orders, [sort.column], [sort.direction]);

  if (orders?.length === 0) {
    return matchedOrders;
  }

  if (orderStatus) {
    matchedOrders = matchedOrders.filter((order) => ORDER_STATUS[orderStatus].includes(order.status));
  }

  return matchedOrders;
}

export default function OrdersCollection() {
  const { t } = useTranslation();
  const location = useLocation();
  const [flash, setFlash] = useState(location.state?.flash);
  const [orders, setOrders] = useState({ new: null, processed: null, shipped: null });
  const [carriers, setCarriers] = useState([]);
  const [refreshOrders, setRefreshOrders] = useState(true);
  const [orderStatus, setOrderStatus] = useState('new');
  const [pageMeta, setPageMeta] = useState({});
  const [page, setPage] = useState({ new: 1, processed: 1, shipped: 1 });
  const [sort, setSort] = useState({ column: 'order_date', direction: 'asc' });
  const fetchOrders = useFetchAllOrders();
  const fetchCarriers = useFetchAllCarriers();
  const [orderReference, setOrderReference] = useState(null);
  const [orderLineStatus, setOrderLineStatus] = useState(null);
  const [processedOrderReference, setProcessedOrderReference] = useState(null);
  const [shippedOrderReference, setShippedOrderReference] = useState(null);

  async function getOrder() {
    // Do not make a new fetch request if user is navigating between tabs
    if (orders[orderStatus] !== null && refreshOrders === false) { return; }

    const result = await fetchOrders(orderStatus, page[orderStatus], PER_PAGE);

    if (Array.isArray(result.data)) {
      setPageMeta((state) => ({ ...state, [orderStatus]: { total_pages: result.meta.total_pages } }));
      setOrders((state) => ({ ...state, [orderStatus]: orderBy(result.data, [sort.column], [sort.direction]) }));
      setRefreshOrders(false);
    } else {
      setFlash({ error: t('components.orders.orders_collection.flash.request_error') });
    }
  }

  const reverseSortDirection = useCallback((column) => {
    const direction = (sort.direction === 'asc') ? 'desc' : 'asc';
    setSort({ column, direction });
  }, [sort]);

  const navigateToPage = useCallback((pageValue) => {
    setRefreshOrders(true);
    setPage((prevState) => ({ ...prevState, [orderStatus]: pageValue }));
  }, [orderStatus]);

  useEffect(() => (async () => getOrder())(), [page, fetchOrders, orderStatus]);

  useEffect(() => {
    (async () => {
      const result = await fetchCarriers();

      if (Array.isArray(result)) {
        setCarriers(result);
      } else {
        setFlash({ error: t('components.orders.orders_collection.flash.request_error') });
      }
    })();
  }, []);

  const filteredOrders = filterOrders(orders[orderStatus], orderStatus, sort);

  function updateOrderStatus(targetReference = '', status = '') {
    setOrders((prevOrders) => ({
      ...prevOrders,
      [orderStatus]: prevOrders[orderStatus].map((order) => (
        order.order_reference !== targetReference ? order : { ...order, status }
      )),
    }));
  }

  const removeProcessedFromOrders = useCallback((index) => {
    const targetReference = orderReference;

    // Auto select the next row for Marking as 'processed'. Ignore previous rows
    if (index >= 0 && filteredOrders[index + 1]) {
      setOrderReference(filteredOrders[index + 1].order_reference);
    } else {
      setOrderLineStatus(null);
      setOrderReference(null);
    }

    updateOrderStatus(targetReference, 'processing_by_wms');
    setProcessedOrderReference(null);
  }, [orderReference]);

  const removeShippedFromOrders = useCallback(() => {
    setOrderLineStatus(null);
    setOrderReference(null);
    updateOrderStatus(orderReference, 'shipped');
    setShippedOrderReference(null);
  }, [orderReference]);

  const markOrderAsProcessed = useCallback(() => {
    const processedOrder = orders[orderStatus].find((order) => order.order_reference === orderReference);

    if (ORDER_STATUS[orderStatus].includes(processedOrder.status)) {
      setProcessedOrderReference(orderReference);
    } else {
      // Skip 'Marked as Processed' animation since the active tab is not equal to the order's state
      // Proceed with moving the processed order
      removeProcessedFromOrders(-99999);
    }
  }, [orderReference, orders, orderStatus]);

  const markOrderAsShipped = useCallback(() => {
    const shippedOrder = orders[orderStatus].find((order) => order.order_reference === orderReference);

    if (ORDER_STATUS[orderStatus].includes(shippedOrder.status)) {
      setShippedOrderReference(orderReference);
    } else {
      // Skip 'Marked as shipped' animation since the active tab is not equal to the order's state
      // Proceed with moving the processed order
      removeShippedFromOrders(-99999);
    }
  }, [orderReference, orders, orderStatus]);

  function setCurrentOrder(salesOrderReference, status) {
    const targetStatus = Object.keys(ORDER_STATUS).find((key) => ORDER_STATUS[key].includes(status));

    if (targetStatus) {
      setOrderLineStatus(targetStatus);
      setOrderReference(salesOrderReference);
    }
  }

  function determineRow(order, idx) {
    switch (order.order_reference) {
      case processedOrderReference:
        return (
          <RemoveOrderLine
            key={order.order_id}
            afterFadeOut={() => removeProcessedFromOrders(idx)}
            message={t('components.orders.collection.processed_order.mark_as_processed')}
          />
        );
      case shippedOrderReference:
        return (
          <RemoveOrderLine
            key={order.order_id}
            afterFadeOut={() => removeShippedFromOrders(idx)}
            message={t('components.orders.collection.processed_order.mark_as_shipped')}
          />
        );
      default:
        return (
          <OrderLine
            key={order.order_id}
            order={order}
            selected={orderReference === order.order_reference}
            setAsCurrentOrder={() => setCurrentOrder(order.order_reference, order.status)}
          />
        );
    }
  }

  return (
    <div className={styles.ordersContainer}>
      <div className={styles.orders}>
        <Flash flash={flash} />
        <FilterOrders onFilterChange={setOrderStatus} orderStatus={orderStatus} />

        {filteredOrders.length > 0
          && (
            <>
              <Table className={`table ${styles['orders-table']} mt-3`}>
                <OrdersTableHeader
                  onClick={reverseSortDirection}
                  sortColumn={sort.column}
                  sortDirection={sort.direction}
                />
                <tbody>
                  { filteredOrders.map((order, idx) => determineRow(order, idx))}
                </tbody>
              </Table>
              <Pagination
                currentPage={page[orderStatus]}
                onClick={navigateToPage}
                pageCount={pageMeta[orderStatus].total_pages ?? 0}
              />
            </>
          )}
      </div>
      <OrderDetails
        orderLineStatus={orderLineStatus}
        carriers={carriers}
        orderReference={orderReference}
        onMarkAsProcessedSuccessful={markOrderAsProcessed}
        onMarkAsShippedSuccessful={markOrderAsShipped}
      />
    </div>
  );
}
