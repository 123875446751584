import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/form/Button';
import styles from './FilterOrders.module.scss';

export default function FilterOrders(props) {
  const { t } = useTranslation();

  return (
    <form className={styles['order-filter-form']}>
      <div className={`btn-group ${styles.filterStatusButtons}`} role="group" aria-label="Filter by attribute">
        <Button
          onClick={() => props.onFilterChange('new')}
          className={`btn btn-outline-primary ${props.orderStatus === 'new' ? styles.selected : ''}`}
          label={t('components.orders.filter_orders.filters.new')}
        />
        <Button
          onClick={() => props.onFilterChange('processed')}
          className={`btn btn-outline-primary ${props.orderStatus === 'processed' ? styles.selected : ''}`}
          label={t('components.orders.filter_orders.filters.processed')}
        />
        <Button
          onClick={() => props.onFilterChange('shipped')}
          className={`btn btn-outline-primary ${props.orderStatus === 'shipped' ? styles.selected : ''}`}
          label={t('components.orders.filter_orders.filters.shipped')}
        />
      </div>
    </form>
  );
}

FilterOrders.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  orderStatus: PropTypes.string.isRequired,
};
