import PropTypes from 'prop-types';
import PaginationItem from 'components/pagination/Item';
import styles from './Pagination.module.scss';

export default function Pagination(props) {
  const { currentPage, pageCount } = props;
  const maxNextPages = 1;
  const maxPagination = 3;

  let endPage = currentPage + maxNextPages;
  if (endPage < maxPagination) {
    endPage = maxPagination;
  }
  if (endPage > pageCount) {
    endPage = pageCount;
  }

  let startPage = endPage - (maxPagination - 1);
  if (startPage < 1) {
    startPage = 1;
  }

  return (
    pageCount > 0
    && (
      <ul className={`${styles.pagination} pt-4`}>
        <PaginationItem
          key="previous"
          active={false}
          disabled={currentPage === 1}
          onClick={props.onClick}
          page={currentPage - 1}
          type="previous"
        />
        { Array.from({ length: endPage - startPage + 1 }, (_, index) => {
          const page = startPage + index;
          return (
            <PaginationItem
              key={`page-${page}`}
              active={page === currentPage}
              disabled={false}
              onClick={props.onClick}
              page={page}
              type="page"
            />
          );
        })}
        <PaginationItem
          key="next"
          active={false}
          disabled={currentPage === pageCount}
          onClick={props.onClick}
          page={currentPage + 1}
          type="next"
        />
      </ul>
    )
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
};
