import { useCallback } from 'react';
import { useFluxRequestHeaders } from 'utils/sessionHelpers';

const API_URL = window.env.REACT_APP_BASE_FLUX_API_URL;
const ORDER_DETAILS_URL = new URL('orders/details', API_URL);
const ORDER_CONFIRM_URL = new URL('orders/confirm', API_URL);
const ORDER_SHIP_URL = new URL('orders/ship', API_URL);

export function useFetchAllOrders() {
  const headers = useFluxRequestHeaders();

  return useCallback(async (type, page, perPage) => {
    let url = null;

    switch (type) {
      case 'processed':
        url = 'orders/processed';
        break;
      case 'shipped':
        url = 'orders/shipped';
        break;
      default:
        url = 'orders';
    }
    const targetUrl = new URL(url, API_URL);
    targetUrl.searchParams.set('page', page);
    targetUrl.searchParams.set('per_page', perPage);

    try {
      const response = await fetch(targetUrl, { headers });
      if (!response.ok) return null;

      return await response.json();
    } catch {
      return null;
    }
  }, [headers]);
}

export function useFetchOrderDetails() {
  const headers = useFluxRequestHeaders();
  const abortFetchOrderDetails = new AbortController();
  const fetchOrderDetails = useCallback(async (orderReference) => {
    ORDER_DETAILS_URL.search = `order_reference=${orderReference}`;
    let response = {};

    try {
      response = await fetch(ORDER_DETAILS_URL, { headers, signal: abortFetchOrderDetails.signal });
      const result = await response.json();

      if (response.ok) {
        return { success: true, data: result.data, aborted: false };
      }

      const { errors } = result;
      return {
        success: false,
        aborted: false,
        statusText: response.statusText,
        errors,
      };
    } catch (error) {
      return {
        success: false,
        aborted: abortFetchOrderDetails.signal.aborted,
        statusText: response.statusText,
        errors: { error, status: response.status },
      };
    }
  }, [headers, abortFetchOrderDetails.signal]);

  return { fetchOrderDetails, abortFetchOrderDetails: () => abortFetchOrderDetails.abort() };
}

export function useConfirmOrder() {
  const headers = useFluxRequestHeaders();
  const abortConfirmOrder = new AbortController();
  const confirmOrder = useCallback(async (data) => {
    let response = {};

    try {
      response = await fetch(ORDER_CONFIRM_URL, {
        headers,
        method: 'POST',
        body: JSON.stringify(data),
        signal: abortConfirmOrder.signal,
      });
      const result = await response.json();

      if (response.ok) {
        return { success: true, data: result.data, aborted: false };
      }

      const { errors } = result;
      return {
        success: false,
        aborted: false,
        statusText: response.statusText,
        errors,
      };
    } catch (error) {
      return {
        success: false,
        aborted: abortConfirmOrder.signal.aborted,
        statusText: response.statusText,
        errors: { error, status: response.status },
      };
    }
  }, [headers, abortConfirmOrder.signal]);

  return { confirmOrder, abortConfirmOrder: () => abortConfirmOrder.abort() };
}

export function useShipOrder() {
  const headers = useFluxRequestHeaders();
  const abortShipOrder = new AbortController();
  const shipOrder = useCallback(async (data) => {
    let response = {};

    try {
      response = await fetch(ORDER_SHIP_URL, {
        headers,
        method: 'POST',
        body: JSON.stringify(data),
        signal: abortShipOrder.signal,
      });
      const result = await response.json();

      if (response.ok) {
        return { success: true, data: result.data, aborted: false };
      }

      const { errors } = result;
      return {
        success: false,
        aborted: false,
        statusText: response.statusText,
        errors,
      };
    } catch (error) {
      return {
        success: false,
        aborted: abortShipOrder.signal.aborted,
        statusText: response.statusText,
        errors: { error, status: response.status },
      };
    }
  }, [headers, abortShipOrder.signal]);

  return { shipOrder, abortShipOrder: () => abortShipOrder.abort() };
}

export default useFetchAllOrders;
