import PropTypes from 'prop-types';
import styles from './Item.module.scss';

export default function Item(props) {
  let content;
  let css;

  switch (props.type) {
    case 'previous':
      content = (<img alt="Previous" className={styles.icon} src="/icons/linear/0836-arrow-left.svg" />);
      css = `${styles.previous} ${props.disabled && styles.disabled}`;
      break;
    case 'next':
      content = (<img alt="Next" className={styles.icon} src="/icons/linear/0837-arrow-right.svg" />);
      css = `${styles.next} ${props.disabled && styles.disabled}`;
      break;
    default:
      content = props.page;
      css = `${props.active && styles.active}`;
      break;
  }

  const handleClick = (e) => {
    e.preventDefault();
    if (!props.active && !props.disabled) props.onClick(props.page);
  };

  return (
    <li>
      <a href="/" onClick={handleClick} className={`${styles['page-link']} ${css}`}>{ content }</a>
    </li>
  );
}

Item.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};
